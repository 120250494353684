import React from "react";

export default function MySettings() {
  return (
    <div>
      <h1>My Settings</h1>
      <p>Welcome to My Settings</p>
    </div>

    // add button and redirect to overview page
  );
}
