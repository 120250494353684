import React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import { Outlet, useNavigate } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import i18next from "../../i18n";
import "./Header.css";
import Logo from "../../images/logo_white.png";
import { US, BR, DE } from "country-flag-icons/react/3x2";
import { Avatar } from "@mui/material";
import SignInSignOutButton from "./SignInSignOutButton";
import WelcomeName from "./WelcomeName";

const countries = [
  {
    code: "en",
    name: "English",
    country_code: "en-us",
  },
  {
    code: "de",
    name: "German",
    country_code: "de",
  },
  {
    code: "pt",
    name: "Portuguese",
    country_code: "pt-br",
  },
];

function ResponsiveAppBar() {
  const [anchorElCountry, setanchorElCountry] =
    React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorElCountry);
  const navigate = useNavigate();

  const handleOpenCountryMenu = (event: React.MouseEvent<HTMLElement>) => {
    setanchorElCountry(event.currentTarget);
    // close menu after clicking
  };

  const handleCloseCountryMenu = () => {
    setanchorElCountry(null);
  };

  const handleLanguageChange = (countryCode: string) => {
    i18next.changeLanguage(countryCode);
    // close menu
    handleCloseCountryMenu();
  };

  interface FlagIconProps {
    language: string;
  }

  const FlagIcon: React.FC<FlagIconProps> = ({ language }) => {
    switch (language) {
      case "en":
        return <US style={{ width: "90%", height: "100%" }} />;
      case "pt":
        return <BR style={{ width: "90%", height: "100%" }} />;
      case "de":
        return <DE style={{ width: "90%", height: "100%" }} />;
      default:
        return null;
    }
  };

  return (
    // TODO understand why margins are not working (class header)
    <>
      <AppBar position="static" className="header" elevation={0}>
        {/* <Container > */}
        <Toolbar disableGutters>
          <Box>
            <Box
              component="img"
              sx={{ height: 50, marginLeft: "10%" }}
              alt="Simplecheck logo."
              src={Logo}
              // on click redirect to /
              onClick={() => navigate("/")}
              style={{ cursor: "pointer" }}
            />
          </Box>

          <Box
            sx={{
              flexGrow: 1,
              maxWidth: "100%",
              mr: "1%",
              ml: "3%",
              // add white letter style always
              ".MuiFilledInput-root": {
                backgroundColor: "#FFFF", // change background color to white
              },

              // add white style background when element is hovered
              ".MuiFilledInput-root:hover": {
                backgroundColor: "#FFFF", // change background color to white
              },

              // add white style background when element is focused
              ".MuiFilledInput-root.Mui-focused": {
                backgroundColor: "#FFFF", // change background color to white
              },
              ".MuiFilledInput-input": {
                color: "#727272", // change text color to grey
              },
            }}
          >
            {/* <TextField
              fullWidth
              size="small"
              label={i18next.t("b2c.header.searchMessage")}
              id="fullWidth"
              variant="filled"
            /> */}
          </Box>

          <Box sx={{ marginRight: "1%" }}>
            <Tooltip title={i18next.t("b2c.header.changeLanguage")}>
              <IconButton onClick={handleOpenCountryMenu} sx={{ p: 0 }}>
                <Avatar sx={{ backgroundColor: "#242E64" }}>
                  <FlagIcon language={i18next.language} />
                </Avatar>
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElCountry}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={open}
              onClose={handleCloseCountryMenu}
            >
              {countries.map((country) => (
                <MenuItem
                  key={country.code}
                  onClick={() => handleLanguageChange(country.code)}
                  disabled={i18next.resolvedLanguage === country.code}
                >
                  <Typography textAlign="center">{country.name}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>

          <Box sx={{ flexGrow: 0, marginRight: "1%" }}>
            <WelcomeName />
            <SignInSignOutButton />
          </Box>
        </Toolbar>
        {/* </Container> */}
      </AppBar>
      <Outlet />
    </>
  );
}
export default ResponsiveAppBar;
