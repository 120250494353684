import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectPatients } from "../../../store/patientB2BSlice";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import tests from "../../../data/vr_test_filtered.json";
import {
  handleObservationRequest,
  handleEncounterRequest,
} from "../../../services/fhir"; // Import the API service
import {
  Observation,
  resetPatientsState,
  Encounter,
} from "../../../store/patientB2BSlice";
import { clearPatientCache } from "../../../services/api";
import { callApi } from "../../../services/MsalApiCall";
import { useTranslation } from "react-i18next";

export default function SubmitObservation() {
  const { t } = useTranslation();
  const patients = useSelector(selectPatients);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSaveToDatabase = async () => {
    // check that all patients have observations
    const allPatientsHaveObservations = Object.values(patients).every(
      (patient: any) => Object.keys(patient.observations).length > 0,
    );
    const { accessToken } = await callApi();

    // check that all observations within each patient have a result
    const allObservationsHaveResults = Object.values(patients).every(
      (patient: any) =>
        Object.values(patient.observations).every(
          (observation: any) => observation.result !== undefined,
        ),
    );

    // alert messages if conditions are not met
    if (!allPatientsHaveObservations) {
      alert(t("b2b.submitObservation.allPatientsMustHaveObservations"));
    } else if (!allObservationsHaveResults) {
      alert(t("b2b.submitObservation.allObservationsMustHaveResults"));
    } else {
      // save observation to database
      Object.values(patients).forEach(async (patient: any) => {
        //TODO update existing encounter

        const responseEncounter = await handleEncounterRequest(
          patient.encounter as Encounter,
          patient.encounter.isNew ? "POST" : "PUT", // check if encounter is new, if new, use POST, else use PUT
          accessToken,
        );

        // existing encounter (update via post)
        Object.entries(
          patient.observations as Record<string, Observation>,
        ).forEach(([loincId, observation]) => {
          handleObservationRequest(
            observation.isNew ? "POST" : "PUT", // check if observation is new, if new, use POST, else use PUT
            accessToken,
            observation as Observation,
            loincId,
            patient.fhirId,
            responseEncounter.id,
          );
        });
      });
      // clear patient cache after saving to database, for every patient email separated by a comma

      // get all patient emails separated by a comma
      const patientEmails = Object.values(patients)
        .map((patient: any) => patient.email)
        .join(",");

      // clear patient cache
      await clearPatientCache(patientEmails, accessToken);

      dispatch(resetPatientsState());

      alert(t("b2b.submitObservation.savedToDatabase"));
      // go back to the home page
      navigate("/");
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={2} // Add spacing between items
          style={{ minHeight: "40vh" }}
        >
          <Grid item xs={8}>
            <TableContainer component={Paper}>
              <Table aria-label="patient observations table">
                <TableHead>
                  <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>{t("b2b.submitObservation.testName")}</TableCell>
                    <TableCell>
                      {t("b2b.submitObservation.testResult")}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Object.values(patients).flatMap((patient: any) => {
                    const observations = Object.entries(
                      patient.observations || {},
                    );
                    if (observations.length === 0) {
                      return (
                        <TableRow key={patient.id}>
                          <TableCell>{patient.email}</TableCell>
                          <TableCell colSpan={2} style={{ color: "red" }}>
                            {t("b2b.submitObservation.noObservations")}
                          </TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                      );
                    } else {
                      return observations.map(
                        ([key, observation]: [string, any]) => {
                          const test = tests.filter(
                            (test: any) => test.LOINC_ID === key,
                          )[0];
                          return (
                            <TableRow key={`${patient.id}-${key}`}>
                              <TableCell>
                                {patient.encounter.encounterId}
                              </TableCell>
                              <TableCell>{patient.email}</TableCell>
                              <TableCell>{test ? test.TEST : ""}</TableCell>
                              <TableCell
                                style={{
                                  color:
                                    observation.result === undefined
                                      ? "red"
                                      : "black",
                                }}
                              >
                                {observation.result === undefined
                                  ? t("b2b.submitObservation.noResult")
                                  : observation.result}
                              </TableCell>
                            </TableRow>
                          );
                        },
                      );
                    }
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              p: 1,
              position: "fixed",
              bottom: 0,
              left: 0,
              right: 0,
            }}
          >
            <Button
              variant="contained"
              onClick={() => navigate("/addobservation")}
            >
              {t("b2b.submitObservation.createOrEditObservation")}
            </Button>
            <Button
              variant="contained"
              onClick={() => handleSaveToDatabase()}
              disabled={Object.keys(patients).length === 0}
            >
              {t("b2b.submitObservation.saveToDatabase")}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
}
