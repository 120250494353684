import React from "react";
import LinePlot from "../../components/plots/LinePlot";
import { Grid } from "@mui/material";
import {
  selectPatientDataB2C,
  selectPatientSettings,
} from "../../store/rawDataB2C";
import { useAppSelector, useAppDispatch } from "../../store/hooks";
import { activeDataB2C } from "../../store/activeDataB2C";
import { transformObjectTo2DArray } from "../../utils/transformObjectTo2DArray";
import updateSelectedObservationThunk from "../../store/thunks/setSelectedObservationThunk";
import Heatmap from "../../components/plots/Heatmap";

export default function ResultsOverview() {
  const patientActiveData = useAppSelector((state) => activeDataB2C(state));
  const dispatch = useAppDispatch();

  const patientSettings = useAppSelector(selectPatientSettings);

  const patientData = useAppSelector((state) => selectPatientDataB2C(state));

  const minValue =
    patientData.referenceData[
      patientActiveData.loincNames[patientActiveData.selectedObservation]
    ]?.min ?? 0;
  const maxValue =
    patientData.referenceData[
      patientActiveData.loincNames[patientActiveData.selectedObservation]
    ]?.max ?? 100;

  const handleselectedObservationChange =
    (x: number, loincID: string) => () => {
      //TOOD needs refactoring to keep only loincID
      dispatch(
        updateSelectedObservationThunk(
          x,
          patientData.metadataClean[loincID].unit,
          patientData.referenceData[loincID],
          patientActiveData.transformedData[loincID],
          patientSettings.unit,
        ),
      );
    };

  const formattedXLabels = patientActiveData.visitDates.map((label: string) =>
    new Date(label).toLocaleString("pt-BR", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    }),
  );

  return (
    <Grid container spacing={2}>
      <Grid item overflow={"auto"} xs={6}>
        <Heatmap
          data={transformObjectTo2DArray(patientActiveData.transformedData)}
          formattedXLabels={formattedXLabels}
          observationNames={patientActiveData.observationNames}
          loincNames={patientActiveData.loincNames}
          metadataClean={patientData.metadataClean}
          referenceData={patientData.referenceData}
          normalizedData={patientData.normalizedData}
          selectedObservation={patientActiveData.selectedObservation}
          handleselectedObservationChange={handleselectedObservationChange}
        />
      </Grid>
      <Grid item xs={6}>
        {Object.keys(patientActiveData.transformedData).length &&
          (patientData.metadataClean[
            patientActiveData.loincNames[patientActiveData.selectedObservation]
          ].type === "string" ? (
            <Heatmap
              data={[
                transformObjectTo2DArray(patientActiveData.transformedData)[
                  patientActiveData.selectedObservation
                ],
              ]}
              formattedXLabels={formattedXLabels}
              observationNames={[
                patientActiveData.observationNames[
                  patientActiveData.selectedObservation
                ],
              ]}
              loincNames={[
                patientActiveData.loincNames[
                  patientActiveData.selectedObservation
                ],
              ]}
              metadataClean={patientData.metadataClean}
              referenceData={patientData.referenceData}
              normalizedData={patientData.normalizedData}
              selectedObservation={patientActiveData.selectedObservation}
            />
          ) : (
            <LinePlot
              chartData={
                transformObjectTo2DArray(patientActiveData.transformedData)[
                  patientActiveData.selectedObservation
                ]
              }
              testName={
                patientActiveData.observationNames[
                  patientActiveData.selectedObservation
                ]
              }
              xLabels={patientActiveData.visitDates}
              title={
                patientActiveData.observationNames[
                  patientActiveData.selectedObservation
                ]
              }
              yLabels={patientActiveData.visitDates}
              ymin={patientActiveData.axisRange[0]}
              ymax={patientActiveData.axisRange[1]}
              refmin={minValue}
              refmax={maxValue}
              unit={patientSettings.unit}
              observationUnit={patientActiveData.observationUnit}
            />
          ))}
      </Grid>
    </Grid>
  );
}
