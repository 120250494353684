// src/components/plots/Heatmap.tsx
import React from "react";
import { HeatMapGrid } from "react-grid-heatmap";

interface HeatmapProps {
  data: number[][];
  formattedXLabels: string[];
  observationNames: string[];
  loincNames: string[];
  metadataClean: any;
  referenceData: any;
  normalizedData: any;
  selectedObservation: number;
  handleselectedObservationChange?: (x: number, loincID: string) => () => void;
}

const Heatmap: React.FC<HeatmapProps> = ({
  data,
  formattedXLabels,
  observationNames,
  loincNames,
  metadataClean,
  referenceData,
  normalizedData,
  selectedObservation,
  handleselectedObservationChange,
}) => {
  const formatValue = (value: number | null): string | null => {
    if (value === null) {
      return null; // Return an empty string for null values
    }

    // count the number of digits after comma, and place that t
    return value.toString();
  };
  // get length of data
  const dataLength = Object.keys(data).length;

  // cellHeight larger if data length is 1
  const cellHeight = dataLength === 1 ? "3.5rem" : "2.5rem";

  const handleClick = (x: number, loincID: string) => {
    if (handleselectedObservationChange) {
      handleselectedObservationChange(x, loincID)();
    }
  };

  return (
    <HeatMapGrid
      // key={sortednormalizedData}
      data={data}
      xLabels={formattedXLabels}
      yLabels={observationNames}
      cellRender={(x, y, value) => {
        // get loincID
        const loincID = loincNames[x];

        // get metadata for test
        const metadata = metadataClean[loincID];

        // if type metadata.type is string, return value
        if (metadata.type === "string") {
          // if value matches reference text, set color to green, else set color to red

          const color =
            value === null
              ? "#CCCCCC" // default color
              : value === referenceData[loincID].text
                ? "#b2d34a"
                : "#830000";

          return (
            <div
              style={{
                backgroundColor: color, // default color
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "relative",
                fontSize: dataLength === 1 ? "0.7rem" : "1rem", // Adjust font size based on data length
                whiteSpace: "nowrap", // Reduce gap between spaces
              }}
            >
              {value && value.toString().length > 4 && dataLength > 1 ? (
                <div title={value?.toString()}>
                  {value?.toString().substring(0, 4)}...
                </div>
              ) : (
                value
              )}
            </div>
          );
        }

        if (!normalizedData[loincID]) {
          return (
            <div
              style={{
                backgroundColor: "white", // default color
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {value}
            </div>
          );
        }

        // get element y

        let absValue = formatValue(
          Object.values(normalizedData[loincID])[y] as number | null,
        );

        // if absValue is not null, run Math.abs
        if (absValue !== null) {
          absValue = Math.abs(Number(absValue)).toString();
        }
        let color;

        // color pallete from https://mycolor.space/?hex=%23F15B40&sub=1
        if (absValue === null) {
          // color grey if null
          color = "#CCCCCC";
        } else if (Number(absValue) >= 150) {
          color = "#830000";
        } else if (Number(absValue) >= 140) {
          color = "#a71009";
        } else if (Number(absValue) >= 130) {
          color = "#cc3a25";
        } else if (Number(absValue) >= 120) {
          color = "#f15b40";
        } else if (Number(absValue) >= 110) {
          color = "#ffc20e";
        } else if (Number(absValue) >= 100) {
          color = "#ffc20e";
        } else {
          color = "#b2d34a"; // default color
        }
        return (
          <div
            style={{
              backgroundColor: color,
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {
              // to do float needs to be adjusted based on each test (TSH vs. cholesterol
              formatValue(value)
            }
          </div>
        );
      }}
      yLabelsStyle={(index) => ({
        fontSize: "1.0rem",
        color: index === selectedObservation ? "#242E64" : "#999",
        fontWeight: index === selectedObservation ? "bold" : "normal",
        whiteSpace: "nowrap",
      })}
      cellStyle={() => ({
        fontSize: "0.9rem",
        color: `white`,
        fontWeight: "bold",
      })}
      cellHeight={cellHeight}
      xLabelsPos="bottom"
      onClick={(x) => {
        const loincID = loincNames[x];
        handleClick(x, loincID);
      }}
      yLabelsPos="left"
      // square
    />
  );
};

export default Heatmap;
