import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import { useNavigate } from "react-router-dom";
import Button from "../../../components/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { TextField } from "@mui/material";
import { searchFHIRResource } from "../../../services/api";
import { callApi } from "../../../services/MsalApiCall";
import { useTranslation } from "react-i18next";
import { Patient } from "../../../store/patientB2BSlice";
import { useDispatch } from "react-redux";
import { Dispatch } from "@reduxjs/toolkit";
import { addPatient, Encounter } from "../../../store/patientB2BSlice";
import PatientList from "../../../components/b2b/PatientList";
import Box from "@mui/material/Box";
import { useAppSelector } from "../../../store/hooks";
import { selectPatients } from "../../../store/patientB2BSlice";

function SearchPatient() {
  const { t } = useTranslation();

  const [identityCard, setIdentityCard] = useState("");
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [birthDate, setBirthDate] = useState("");
  const [foundPatients, setFoundPatients] = useState<Patient[]>([]);
  const [isSearching, setIsSearching] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const patients = useAppSelector(selectPatients);
  const isEditingPatient = useAppSelector(
    (state: any) => state.settingsB2B.isEditingPatient,
  );

  const handleInputChange =
    (setter: React.Dispatch<React.SetStateAction<string>>) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setter(event.target.value);
    };

  const handleSearchTrigger = async () => {
    try {
      setIsSearching(true);
      const queryParams = [];
      if (identityCard) queryParams.push(`identifier=${identityCard}`);
      if (name) queryParams.push(`name=${name}`);
      if (surname) queryParams.push(`family=${surname}`);
      if (birthDate) queryParams.push(`birthdate=${birthDate}`);

      if (queryParams.length === 0) {
        alert("b2b.searchPatient.atLeastOneFieldInSearch");
        return;
      }
      const { accessToken } = await callApi();
      const queryString = queryParams.join("&");

      const response = await searchFHIRResource(
        `/Patient?${queryString}`,
        accessToken,
      );

      setFoundPatients(response.entry);
      setIsSearching(false);
    } catch (error) {
      console.error("Error fetching patients:", error);
      setIsSearching(false);
    }
  };

  const handleKeyDown = async (
    event: React.KeyboardEvent<HTMLInputElement>,
  ) => {
    if (event.key === "Enter") {
      await handleSearchTrigger();
    }
  };

  const handleAddPatient = (patientFromFHIR: any, dispatch: Dispatch) => {
    const newPatient: Patient = {
      fhirId: patientFromFHIR?.id || "",
      isNewPatient: false,
      nameGiven: patientFromFHIR?.name?.[0]?.given?.[0] || "",
      nameFamily: patientFromFHIR?.name?.[0]?.family || "",
      identifierValue: patientFromFHIR?.identifier?.[0]?.value || "",
      identifierType:
        patientFromFHIR?.identifier?.[0]?.type?.coding?.[0]?.code || "",
      phone: patientFromFHIR?.telecom?.[0]?.value || "",
      email: patientFromFHIR?.telecom?.[1]?.value || "", //TODO improve how telecon is handled in telecom array
      sex: patientFromFHIR?.gender || "",
      birthDate: patientFromFHIR?.birthDate || "",
      addressStreet: patientFromFHIR?.address?.[0]?.line?.[0] || "",
      addressStreetNumber: patientFromFHIR?.address?.[0]?.line?.[1] || "",
      addressNeighborhood: patientFromFHIR?.address?.[0]?.line?.[2] || "",
      state: patientFromFHIR?.address?.[0]?.state || "",
      city: patientFromFHIR?.address?.[0]?.city || "",
      country: patientFromFHIR?.address?.[0]?.country || "",
      postalCode: patientFromFHIR?.address?.[0]?.postalCode || "",
      observations: {},
      encounter: {} as Encounter,
    };

    dispatch(addPatient(newPatient));
  };

  return (
    <Grid container spacing={2}>
      <PatientList />
      <Grid item xs={9}>
        <Grid
          container
          spacing={3}
          marginTop={5}
          sx={{
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Grid item>
            <TextField
              label={t("b2b.searchPatient.identity")}
              size="small"
              value={identityCard}
              onChange={handleInputChange(setIdentityCard)}
              onKeyDown={handleKeyDown}
              disabled={isSearching}
              variant={isSearching ? "filled" : "outlined"}
            />
          </Grid>
          <Grid item>
            <TextField
              label={t("b2b.searchPatient.name")}
              size="small"
              value={name}
              onChange={handleInputChange(setName)}
              onKeyDown={handleKeyDown}
              disabled={isSearching}
              variant={isSearching ? "filled" : "outlined"}
            />
          </Grid>
          <Grid item>
            <TextField
              label={t("b2b.searchPatient.surname")}
              size="small"
              value={surname}
              onChange={handleInputChange(setSurname)}
              onKeyDown={handleKeyDown}
              disabled={isSearching}
              variant={isSearching ? "filled" : "outlined"}
            />
          </Grid>
          <Grid item>
            <TextField
              id={t("b2b.searchPatient.bithdate")}
              label={t("b2b.searchPatient.birthDate")}
              disabled={isSearching}
              variant={isSearching ? "filled" : "outlined"}
              type="date"
              InputLabelProps={{ shrink: true }}
              value={birthDate}
              onChange={handleInputChange(setBirthDate)}
              onKeyDown={handleKeyDown}
              inputProps={{
                max: new Date().toISOString().split("T")[0], // Use inputProps to set the max attribute
              }}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container justifyContent="center" alignItems="center">
            <Grid item>
              <TableContainer component={Paper}>
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell>{"Email"}</TableCell>
                      <TableCell>{t("b2b.searchPatient.name")}</TableCell>
                      <TableCell>{t("b2b.searchPatient.surname")}</TableCell>
                      <TableCell>{t("b2b.searchPatient.birthDate")}</TableCell>
                      <TableCell>{t("b2b.searchPatient.identity")}</TableCell>
                      <TableCell>{t("b2b.searchPatient.action")}</TableCell>
                    </TableRow>
                  </TableHead>
                  {foundPatients && Object.keys(foundPatients).length !== 0 ? (
                    <TableBody>
                      {foundPatients.map((patient: any) => {
                        return (
                          <TableRow key={patient.resource.id}>
                            <TableCell>
                              {patient.resource.telecom &&
                              patient.resource.telecom[1]
                                ? patient.resource.telecom[1].value
                                : "N/A"}
                            </TableCell>
                            <TableCell>
                              {patient.resource.name && patient.resource.name[0]
                                ? patient.resource.name[0].given
                                : "N/A"}
                            </TableCell>
                            <TableCell>
                              {patient.resource.name && patient.resource.name[0]
                                ? patient.resource.name[0].family
                                : "N/A"}
                            </TableCell>
                            <TableCell>
                              {patient.resource.birthDate
                                ? patient.resource.birthDate
                                : "N/A"}
                            </TableCell>
                            <TableCell>
                              {patient.resource.identifier &&
                              patient.resource.identifier[0]
                                ? patient.resource.identifier[0].value
                                : "N/A"}
                            </TableCell>
                            <TableCell>
                              <Button
                                variant="outlined"
                                color="info"
                                onClick={() => {
                                  handleAddPatient(patient.resource, dispatch);
                                }}
                              >
                                {t("b2b.searchPatient.newVisit")}
                              </Button>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  ) : (
                    // create a single red line in table telling user that there are no patients
                    <TableRow>
                      <TableCell
                        colSpan={6}
                        style={{
                          color: "red",
                          textAlign: "center",
                        }}
                      >
                        {t("b2b.searchPatient.noPatients")}
                        <Grid
                          container
                          justifyContent="center"
                          style={{ marginTop: "10px" }}
                        >
                          <Button
                            variant="outlined"
                            color="primary"
                            onClick={() => {
                              navigate("/createpatient");
                            }}
                          >
                            {t("b2b.searchPatient.createNewPatient")}
                          </Button>
                        </Grid>
                      </TableCell>
                    </TableRow>
                  )}
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Grid>
        <Grid>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between", // This will space out the child elements to the extreme ends
              p: 1, // Padding for spacing around the buttons
              position: "fixed", // Fix position to the bottom of the screen
              bottom: 0,
              left: 0,
              right: 0, // Stretch across the entire width
            }}
          >
            <Button
              variant="contained"
              onClick={() => navigate("/createpatient")}
            >
              {t("b2b.addObservation.createOrEditPatient")}
            </Button>
            <Button
              variant="contained"
              onClick={() => navigate("/addobservation")}
              disabled={Object.keys(patients).length === 0 || isEditingPatient} // Example condition, adjust as needed
            >
              {t("b2b.createPatient.addObservationButton")}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default SearchPatient;
