import { Navigate } from "react-router-dom";
import React from "react";
import Overview from "./Overview";
import AddObservation from "./AddObservation/AddObservation";
import CreatePatient from "./CreatePatient/CreatePatient";
import SubmitObservation from "./SubmitObservation/SubmitObservation";
import SearchPatient from "./SearchPatient/SearchPatient";

export const b2bRoutes = [
  {
    path: "",
    element: <Navigate to="/overview" />,
    index: true,
  },
  {
    path: "overview",
    element: <Overview />,
  },
  {
    path: "searchpatient",
    element: <SearchPatient />,
  },
  {
    path: "addobservation",
    element: <AddObservation />,
  },
  {
    path: "createpatient",
    element: <CreatePatient />,
  },
  {
    path: "patient/:pid",
    element: <CreatePatient />,
  },
  {
    path: "submitobservation",
    element: <SubmitObservation />,
  },
];
