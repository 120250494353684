import {
  updateTransformedData,
  updateLoincNames,
  updateObservationNames,
  updateVisitDates,
} from "../../store/activeDataB2C";
import updateSelectedObservationThunk from "../thunks/setSelectedObservationThunk";

function setTransformedDataThunk(patientData: any, filter: string) {
  const sortedMatrix: any = {};
  let sortedKeys: string[] = [];

  if (filter === "Reference values") {
    const stats = patientData.statisticsData[filter];

    // Get the keys of the rawData object
    const data_keys = Object.keys(patientData.rawData);

    // Sort the indices array based on the corresponding values in `values`
    data_keys.sort((a: string, b: string) => {
      const valueA = stats[a]?.[Object.keys(stats[a])[0]] ?? null;
      const valueB = stats[b]?.[Object.keys(stats[b])[0]] ?? null;

      if (valueA === null && valueB === null) return 0;
      if (valueA === null) return 1;
      if (valueB === null) return -1;

      return valueB - valueA;
    });

    // Store the sorted keys
    sortedKeys = [...data_keys];

    // Sort the rawData based on the sorted keys
    sortedKeys.forEach((key) => {
      sortedMatrix[key] = patientData.rawData[key];
    });
  }

  if (filter === "Alphabetical order") {
    // Get the keys from rawData
    const data_keys = Object.keys(patientData.rawData);

    // Sort the keys based on metadataClean[data_keys].name
    data_keys.sort((a, b) =>
      patientData.metadataClean[a].name.localeCompare(
        patientData.metadataClean[b].name,
      ),
    );

    // Store the sorted keys
    sortedKeys = [...data_keys];

    // Sort the rawData based on the sorted keys
    sortedKeys.forEach((key) => {
      sortedMatrix[key] = patientData.rawData[key];
    });
  }

  // for every patientData.metadataClean, iterate over sorted keys and get the name
  const observationNames = sortedKeys.map(
    (key: string) => patientData.metadataClean[key].name,
  );

  const observationUnit = patientData.metadataClean[sortedKeys[0]].unit;

  const visitNames = Object.keys(sortedMatrix[sortedKeys[0]]);

  return (dispatch: any) => {
    dispatch(updateTransformedData(sortedMatrix));
    dispatch(updateLoincNames(sortedKeys));
    dispatch(updateObservationNames(observationNames));
    dispatch(updateVisitDates(visitNames));
    dispatch(
      updateSelectedObservationThunk(
        0,
        observationUnit,
        patientData.referenceData[sortedKeys[0]],
        sortedMatrix[sortedKeys[0]],
        "Absolute", //always start as absolute, can be changed in activeData
      ),
    );
  };
}

export default setTransformedDataThunk;
