import type { Observation } from "../../store/patientB2BSlice";
import {
  splitOperatorNumericInput,
  SplitResult,
} from "../../utils/referenceValue";
import { convertToDateTimeLocal } from "../../utils/convertToDateTimeLocal";

export const createFhirObservation = (
  observation: Observation,
  loinc: string,
  patientFhirId: string,
  encounterId: string,
): any => {
  // convert observation times from string 2023-09-03T17:09 to iso
  const effectiveTimeISO = new Date(observation.effectiveTime).toISOString();

  const issuedTimeISO = new Date(observation.issuedTime).toISOString();

  // comparators are not allowed in referenceRange, the default is <= for low and >= for high
  // for cases with < or > we need to split the operator and value and make value a float with several decimal places
  // see https://www.hl7.org/fhir/observation-definitions.html#Observation.referenceRange
  const lowVRLimit: SplitResult = splitOperatorNumericInput(
    String(observation.referenceRangeLow) || "",
  );

  // if comparator is > or <. add a small decimal to the value to avoid errors in the fhir server
  const lowVRLimitNumber = lowVRLimit.number;
  if (lowVRLimit.operator === "<" && lowVRLimitNumber !== null) {
    lowVRLimit.number = lowVRLimitNumber - 0.0000001;
  } else if (lowVRLimit.operator === ">" && lowVRLimitNumber !== null) {
    lowVRLimit.number = lowVRLimitNumber + 0.0000001;
  }

  const highVRLimit: SplitResult = splitOperatorNumericInput(
    String(observation.referenceRangeHigh) || "",
  );

  const highVRLimitNumber = highVRLimit.number;
  if (highVRLimit.operator === "<" && highVRLimitNumber !== null) {
    highVRLimit.number = highVRLimitNumber - 0.0000001;
  } else if (highVRLimit.operator === ">" && highVRLimitNumber !== null) {
    highVRLimit.number = highVRLimitNumber + 0.0000001;
  }

  const observationResource: any = {
    resourceType: "Observation",
    meta: {
      profile: [
        "http://www.saude.gov.br/fhir/r4/StructureDefinition/BRDiagnosticoLaboratorioClinico-3.2.1",
      ],
    },
    status: "final",
    category: [
      {
        coding: [
          {
            system:
              "http://www.saude.gov.br/fhir/r4/CodeSystem/BRSubgrupoTabelaSUS",
            code: "0202",
            display: "Diagnóstico em laboratório clínico",
          },
        ],
      },
    ],
    code: {
      coding: [
        {
          system: "http://loinc.org",
          code: loinc,
          display: observation.name,
        },
      ],
      text: observation.name,
    },
    subject: {
      reference: "Patient/" + patientFhirId,
      identifier: {
        system:
          "http://www.saude.gov.br/fhir/r4/StructureDefinition/BRIndividuo-1.0",
        value:
          "http://www.saude.gov.br/fhir/r4/StructureDefinition/BRIndividuo-1.0",
      },
    },
    effectiveDateTime: effectiveTimeISO, // blood drawn
    issued: issuedTimeISO, // result issued
    performer: [
      {
        reference: "Organization/b1307e26-12aa-4849-84f9-3af9a55d5313",
        type: "Organization",
        identifier: {
          system:
            "http://www.saude.gov.br/fhir/r4/StructureDefinition/BREstabelecimentoSaude-1.0",
          value:
            "http://www.saude.gov.br/fhir/r4/StructureDefinition/BREstabelecimentoSaude-1.0",
        },
      },
    ],
    method: {
      text: observation.method,
    },
    specimen: {
      reference: "Specimen/" + observation.specimen.fhirId, // not sure if this is needed
    },
    encounter: {
      reference: `Encounter/${encounterId}`,
    },
  };

  // add fhirId if it exists
  if (observation.fhirId) {
    observationResource.id = observation.fhirId;
  }

  // add reference values
  if (observation.type === "numeric" || observation.type === "qualitative") {
    const referenceRange: any = {};

    if (
      observation.referenceRangeLow !== "" &&
      observation.referenceRangeLow !== null
    ) {
      referenceRange.low = {
        value: lowVRLimitNumber, // only accepts decimal
        ...(observation.unit && {
          unit: observation.unit,
          system: "http://unitsofmeasure.org",
          code: observation.unit,
        }),
      };
    }

    if (
      observation.referenceRangeHigh !== "" &&
      observation.referenceRangeHigh !== null
    ) {
      referenceRange.high = {
        value: highVRLimitNumber, // only accepts decimal
        ...(observation.unit && {
          unit: observation.unit,
          system: "http://unitsofmeasure.org",
          code: observation.unit,
        }),
      };
    }

    const minText =
      referenceRange.low !== null
        ? `min: ${observation.referenceRangeLow}`
        : "";

    const maxText =
      referenceRange.high !== null
        ? `max: ${observation.referenceRangeHigh}`
        : "";

    if (minText || maxText) {
      referenceRange.text = `${minText}${maxText}`;
    }

    observationResource.referenceRange = [referenceRange];
  } else {
    observationResource.referenceRange = [
      {
        text: observation.referenceRangeText,
      },
    ];
  }

  // add results
  if (observation.type === "string") {
    observationResource.valueString = observation.result;
  } else {
    if (observation.result === undefined || observation.result === null) {
      throw new Error(
        "Observation result is required for numeric or qualitative types",
      );
    }

    observationResource.valueQuantity = {
      value: parseFloat(observation.result.toString()), // convert result to decimal
      ...(observation.unit && {
        unit: observation.unit, // string
        system: "http://unitsofmeasure.org",
        code: observation.unit, // code //TODO check correct value
      }),
    };
  }

  return observationResource;
};

export const convertFhirToObservation = (
  fhirObservation: any,
): { loincId: string; observation: Observation } => {
  const loincId: string = fhirObservation.code.coding[0].code;
  const observation: Observation = {
    fhirId: fhirObservation.id,
    isNew: false,
    name: fhirObservation.code.text,
    effectiveTime: convertToDateTimeLocal(fhirObservation.effectiveDateTime),
    issuedTime: convertToDateTimeLocal(fhirObservation.issued),
    method: fhirObservation.method?.text,
    specimen: fhirObservation.specimen?.reference.split("/")[1],
    type: fhirObservation.valueString ? "string" : "numeric",
    unit: fhirObservation.valueQuantity?.unit,
    result: fhirObservation.valueString || fhirObservation.valueQuantity?.value,
    referenceRangeLow: "",
    referenceRangeHigh: "",
    referenceRangeText: "",
  };

  if (fhirObservation.referenceRange) {
    const referenceRange = fhirObservation.referenceRange[0];
    if (referenceRange.low) {
      observation.referenceRangeLow = `${referenceRange.low.value}`;
    }

    if (referenceRange.high) {
      observation.referenceRangeHigh = `${referenceRange.high.value}`;
    }

    if (referenceRange.text) {
      observation.referenceRangeText = referenceRange.text;
    }
  }

  return { loincId: loincId, observation };
};
