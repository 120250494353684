import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector) // detects the current language
  .use(Backend)
  .init({
    fallbackLng: "en",
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    backend: {
      loadPath: "/locale/{{lng}}/translation.json",
    },
  });

export default i18n;

// import i18n from "i18next";
// import LanguageDetector from "i18next-browser-languagedetector";
// import Backend from "i18next-xhr-backend";
// import { initReactI18next } from "react-i18next";

// const fallbackLng = ["fr"];

// i18n
//   .use(Backend) // used to load data from othe directory
//   .use(LanguageDetector) // detects the current language
//   .use(initReactI18next) // passes i18n down to react-i18next
//   .init({
//     fallbackLng, // default language
//     detection: {
//       checkWhitelist: true,
//     },
//     debug: true,
//     interpolation: {
//       escapeValue: false, // no need for react. it escapes by default
//     },
//     resources:{
//       en:{
//         hello: "hello, react",
//         cancel: "cancel react",
//       },
//       fr:{
//         hello: "bonjour, react",
//         cancel: "sdfsfd react",
//       }
//     }
//   });

// export default i18n;
