import React from "react";
import { Box, Typography, Link, Button, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function ContactUs() {
  const navigate = useNavigate();

  const handleRedirect = () => {
    navigate("/overview");
  };

  return (
    <Box sx={{ flexGrow: 1, margin: "50px" }}>
      <h1>Contato</h1>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        width="1000px"
      >
        <Grid container spacing={4} justifyContent="center">
          <Grid item xs={12} md={6}>
            <Box
              border={1}
              borderRadius={2}
              padding={3}
              textAlign="center"
              boxShadow={3}
            >
              <Typography variant="h5" gutterBottom>
                Masi Laboratório
              </Typography>
              <Typography>
                Email:{" "}
                <Link href="mailto:laboratorio@masilab.com.br">
                  laboratorio@masilab.com.br
                </Link>
              </Typography>
              <Typography>
                Telefone: <Link href="tel:+555133466100">+55 51 3346-6100</Link>
              </Typography>
              <Typography variant="h6" gutterBottom>
                Central de Atendimento
              </Typography>
              <Typography>Av. Cristóvão Colombo, 1156</Typography>
              <Typography>Fone: (51) 3346-6100</Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box
              border={1}
              borderRadius={2}
              padding={3}
              textAlign="center"
              boxShadow={3}
            >
              <Typography variant="h5" gutterBottom>
                Simplecheck Analytics
              </Typography>
              <Typography>
                Email:{" "}
                <Link href="mailto:support@simplecheck.com.br">
                  contato@simplecheck.com.br
                </Link>
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Box marginTop={4}>
          <Button variant="contained" color="primary" onClick={handleRedirect}>
            Voltar para a página de visão geral
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
