import { TestVR } from "../types/reference_values";
import { convertAgeSpecToDays } from "../utils/convertAgeSpecToDays";
import { ValidateInputTestType } from "../utils/validateInput";

// Utility function to filter test metadata
type Gender = "male" | "female" | "";

export interface SplitResult {
  operator: string | null;
  number: number | null;
}

export function splitOperatorNumericInput(input: string | null): SplitResult {
  const operatorRegex = /^(>=|<=|>|<|==|!=)/;
  if (input === null) {
    return { operator: null, number: null };
  }
  const match = input.match(operatorRegex);

  if (match) {
    const operator = match[0];
    const number = parseFloat(input.slice(operator.length));
    return { operator, number };
  } else {
    return { operator: null, number: parseFloat(input) };
  }
}

export const filterReferenceValue = (
  testMetadata: TestVR[],
  days: number,
  sex: Gender,
): ValidateInputTestType => {
  const filteredMetadata = testMetadata.filter((test) => {
    let testMinDays = null;
    let testMaxDays = null;
    let testOperatorMin = null;

    // filter test matching sex
    if (test.SEX !== sex && test.SEX !== "") {
      return false;
    }

    if (test.AGE_MIN === "" && test.AGE_MAX === "") {
      return true;
    }

    if (test.AGE_MIN === "" && test.AGE_MAX === "") {
      return true;
    }

    if (test.AGE_MIN && test.AGE_MIN !== "") {
      const ageSpecResult = convertAgeSpecToDays(test.AGE_MIN);

      testOperatorMin = ageSpecResult.operator;
      testMinDays = ageSpecResult.days;
    }

    if (test.AGE_MAX && test.AGE_MAX !== "") {
      const ageSpecResult = convertAgeSpecToDays(test.AGE_MAX);
      testMaxDays = ageSpecResult.days;
    }

    // if days is in between testMinDays and testMaxDays, return true
    if (testMinDays !== null && testMaxDays !== null) {
      return days >= testMinDays && days <= testMaxDays;
    }

    // if testMinDays is not null and testMaxDays is null, < or above the range
    if (testMinDays !== null && testMaxDays === null) {
      if (testOperatorMin === ">") {
        return days > testMinDays;
      }
      // if operator is <, check that days is below testMinDays range
      if (testOperatorMin === "<") {
        return days < testMinDays;
      }
    }
    return false;
  });

  return {
    type: filteredMetadata[0]?.TYPE as string,
    minNumeric: filteredMetadata[0]?.VR_MIN as string,
    maxNumeric: filteredMetadata[0]?.VR_MAX as string,
    textNegative: filteredMetadata[0]?.VR_TEXT_NEGATIVE as string,
    textPositive: filteredMetadata[0]?.VR_TEXT_POSITIVE as string,
  };
};
