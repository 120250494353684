import { updateSelectedObservation } from "../activeDataB2C";
import { ValidateInputTestType } from "../../utils/validateInput";

function updateSelectedObservationThunk(
  selectedObservation: number,
  observationUnit: string,
  referenceValues: ValidateInputTestType,
  rawPatientData: number[],
  unit: string,
) {
  let axisRange: number[] = [0, 100];
  const minFromVR = referenceValues.minNumeric as number | null;
  const maxFromVR = referenceValues.maxNumeric as number | null;

  // set axis range
  let minFromData = Math.min(...Object.values(rawPatientData));
  let maxFromData = Math.max(...Object.values(rawPatientData));

  if (minFromVR !== null && minFromVR < minFromData) {
    minFromData = minFromVR;
  }
  if (maxFromVR !== null && maxFromVR > maxFromData) {
    maxFromData = maxFromVR;
  }

  let roundDown = Math.floor(minFromData * 0.8);
  const roundUp = Math.ceil(maxFromData * 1.2);

  // if minFromVR is null, set it to zero
  if (minFromVR == null) {
    roundDown = 0;
  }

  if (unit === "Absolute") {
    axisRange = [roundDown, roundUp];
  }

  if (unit === "% from reference") {
    axisRange = [-200, 200];
  }

  return (dispatch: any) => {
    dispatch(
      updateSelectedObservation({
        selectedObservation,
        observationUnit,
        axisRange,
      }),
    );
  };
}

export default updateSelectedObservationThunk;
