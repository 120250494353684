import { configureStore, Action, ThunkAction } from "@reduxjs/toolkit";
import patientsReducer from "./patientB2BSlice";
import { settingsB2BSlice } from "./settingsB2B";
import rawDataB2CReducer from "./rawDataB2C";
import { activeDataB2CSlice } from "./activeDataB2C";

const store = configureStore({
  reducer: {
    patients: patientsReducer,
    settingsB2B: settingsB2BSlice.reducer,
    rawDataB2C: rawDataB2CReducer,
    activeDataB2C: activeDataB2CSlice.reducer,
  },
});

export default store;

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
