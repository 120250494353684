import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import React from "react";
import annotationPlugin from "chartjs-plugin-annotation";
import { useTranslation } from "react-i18next";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  annotationPlugin,
);

export const options = (
  title: string,
  ymin: number,
  ymax: number,
  refmin: number,
  refmax: number,
  unit: string,
  observationUnit: string,
  visitDate: string,
) => {
  const minRefAnnotation = {
    type: "line" as const, // assert as "box"
    // xScaleID: "x",
    // yScaleID: "y",
    yMin: refmin,
    yMax: refmin,
    borderColor: "rgba(245, 100, 0, 0.6)",
    borderWidth: 2,
    borderDash: [5, 5],
  };

  const maxRefAnnotation = {
    type: "line" as const, // assert as "box"
    // xScaleID: "x",
    // yScaleID: "y",
    yMin: refmax,
    yMax: refmax,
    borderColor: "rgba(245, 100, 0, 0.6)",
    borderWidth: 2,
    borderDash: [5, 5],
  };

  const maxRefAnnotationBox = {
    type: "box" as const, // assert as "box"
    xScaleID: "x",
    yScaleID: "y",
    yMin: refmax,
    yMax: ymax,
    backgroundColor: "rgba(255, 99, 71, 0.15)",
    borderColor: "transparent",
    borderWidth: 0,
  };

  const minRefAnnotationBox = {
    type: "box" as const, // assert as "box"
    xScaleID: "x",
    yScaleID: "y",
    yMin: refmin,
    yMax: ymin,
    backgroundColor: "rgba(255, 99, 71, 0.15)",
    borderColor: "transparent",
    borderWidth: 0,
  };

  type Annotation =
    | {
        type: "box";
        xScaleID: string;
        yScaleID: string;
        yMin: number;
        yMax: number;
        backgroundColor: string;
        borderColor: string;
        borderWidth: number;
      }
    | {
        type: "label";
        xScaleID: string;
        yScaleID: string;
        yMin: number;
        yMax: number;
        align: string;
        backgroundColor: string;
        borderColor: string;
        content: string;
        borderWidth: number;
      }
    | {
        type: "line";
        yMin: number;
        yMax: number;
        borderColor: string;
        borderWidth: number;
      };

  const fillAnnotation = {
    type: "box" as const,
    xScaleID: "x",
    yScaleID: "y",
    yMin: refmin,
    yMax: refmax,
    backgroundColor: "rgba(37, 162, 31, 0.15)",
    borderColor: "transparent",
    borderWidth: 0,
  };

  const annotations: Annotation[] = [
    minRefAnnotation,
    maxRefAnnotation,
    fillAnnotation,
    maxRefAnnotationBox,
    minRefAnnotationBox,
  ];

  if (refmin !== 0) {
    const minRefText = {
      type: "label" as const,
      xScaleID: "x",
      yScaleID: "y",
      yMin: refmin,
      yMax: refmin < 0 ? refmin * 1.15 : refmin * 0.75,
      align: "center",
      backgroundColor: "transparent",
      borderColor: "transparent",
      content: "Minimum reference value",
      borderWidth: 1,
      color: "darkred",
    };

    annotations.push(minRefText);
  }

  if (refmax > 0) {
    const maxRefText = {
      type: "label" as const,
      xScaleID: "x",
      yScaleID: "y",
      yMin: refmax,
      yMax: refmax * 1.1,
      align: "center",
      backgroundColor: "transparent",
      borderColor: "transparent",
      content: "Maximum reference value",
      borderWidth: 1,
      color: "darkred",
    };

    annotations.push(maxRefText);
  }
  return {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      annotation: {
        annotations,
      },
    },
    scales: {
      y: {
        min: ymin,
        max: ymax,
        grid: {
          display: false,
        },
        title: {
          display: true,
          text:
            title +
            (unit === "Absolute"
              ? " (" + observationUnit + ")"
              : " (% from reference value)"),
          // increase font size
          font: {
            size: 15,
          },
        },
      },
      x: {
        grid: {
          display: false,
        },
        title: {
          display: true,
          text: visitDate,
          // increase font size
          font: {
            size: 15,
          },
        },
      },
    },
  };
};

interface CardProps {
  title: string;
  yLabels: Array<string>;
  chartData: any;
  ymin: number;
  ymax: number;
  refmin: number;
  refmax: number;
  xLabels: string[];
  testName: string;
  unit: string;
  observationUnit: string;
}

const LinePlot: React.FC<CardProps> = ({
  title,
  chartData,
  ymin,
  ymax,
  refmin,
  refmax,
  xLabels,
  testName,
  unit,
  observationUnit,
}) => {
  const { t } = useTranslation();
  const formattedXLabels = xLabels.map((label: string) =>
    new Date(label).toLocaleString("pt-BR", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    }),
  ); // TODO when redux is implemented, this should be done in the selector
  const data = {
    labels: formattedXLabels,
    datasets: [
      {
        label: testName || "Test data",
        data: chartData,
        fill: false,
        pointBorderColor: "darkblue",
        pointBackgroundColor: chartData
          ? chartData.map((value: number) =>
              value > refmax || value < refmin ? "red" : "darkblue",
            )
          : "darkblue",
        borderColor: "lightgrey",
        backgroundColor: "lightblue",
        pointRadius: 4,
      },
    ],
  };

  return (
    <Line
      data={data}
      options={options(
        title,
        ymin,
        ymax,
        refmin,
        refmax,
        unit,
        observationUnit,
        t("b2c.linePlot.visitDate"),
      )}
    />
  );
};

export default LinePlot;
