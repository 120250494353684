import { Encounter } from "../../store/patientB2BSlice";

export const createFhirEncounter = (encounterData: Encounter): any => {
  const periodStart = new Date(encounterData.periodStart).toISOString();
  const periodEnd = new Date(encounterData.periodEnd).toISOString();

  const fhirEncounter: any = {
    resourceType: "Encounter",
    meta: {
      profile: [
        "https://simplifier.net/redenacionaldedadosemsaude/brcontatoassistencial",
      ],
    },
    identifier: [
      {
        use: "official",
        system: "/Organization/b1307e26-12aa-4849-84f9-3af9a55d5313",
        value: encounterData.encounterId || "",
      },
    ],
    status: "finished",
    class: {
      system: "http://terminology.hl7.org/CodeSystem/v3-ActCode",
      code: "AMB",
    },
    // type: [
    //   {
    //     coding: [
    //       {
    //         system: "http://snomed.info/sct",
    //         code: "162673000",
    //         display: "General examination of patient (procedure)",
    //       },
    //     ],
    //     text: "General examination of patient (procedure)",
    //   },
    // ],
    subject: {
      reference: `Patient/${encounterData.patientFhirId}`,
    },
    // participant: [
    //   {
    //     type: [
    //       {
    //         coding: [
    //           {
    //             system: "http://terminology.hl7.org/CodeSystem/v3-ParticipationType",
    //             code: "PPRF",
    //             display: "primary performer",
    //           },
    //         ],
    //         text: "primary performer",
    //       },
    //     ],
    //     period: {
    //       start: encounterData.participantPeriodStart || "2015-07-22T18:39:51+05:30",
    //       end: encounterData.participantPeriodEnd || "2015-07-22T18:54:51+05:30",
    //     },
    //     individual: {
    //       reference: `Practitioner/${encounterData.practitionerId}`,
    //       display: encounterData.practitionerDisplay || "Dr. Austin18 Test18",
    //     },
    //   },
    // ],
    period: {
      start: periodStart,
      end: periodEnd,
    },
    // location: [
    //   {
    //     location: {
    //       reference: `Location/${encounterData.locationId}`,
    //       display: encounterData.locationDisplay || "Loc Health Provider 11",
    //     },
    //   },
    // ],
    serviceProvider: {
      reference: "Organization/b1307e26-12aa-4849-84f9-3af9a55d5313",
      display: "LABORATORIO BIOANALISES S/S LTDA (Masi Laboratório)",
    },
  };

  if (encounterData.fhirId) {
    fhirEncounter.id = encounterData.fhirId;
  }
  return fhirEncounter;
};
