// src/services/api.ts

const APP_BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

export async function fetchData(uri: string, accessToken: string) {
  try {
    const response = await fetch(APP_BACKEND_URL + uri, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (!response.ok) {
      // print error code
      throw new Error(`${response.status}`);
    }
    return await response.json();
  } catch (error: any) {
    throw new Error(error.message);
  }
}

export async function searchFHIRResource(
  queryString: string,
  accessToken: string,
) {
  const formData = new FormData();
  formData.append("query_string", queryString);

  const data = fetch(APP_BACKEND_URL + "/search", {
    method: "POST",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    body: formData,
  })
    .then((response) => response.json())
    .catch((error) => {
      console.error("Error:", error);
    });

  return data;
}

export async function fetchDataStatistics(
  uri: string,
  base: string,
  accessToken: string,
) {
  try {
    const response = await fetch(APP_BACKEND_URL + uri, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({
        base: base,
      }),
    });
    return await response.json();
  } catch (error) {
    return console.error("Error:", error);
  }
}

export async function clearPatientCache(emails: string, accessToken: string) {
  try {
    const response = await fetch(APP_BACKEND_URL + "/api/cache/clear", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({
        emails: emails,
      }),
    });
    return await response.json();
  } catch (error) {
    return console.error("Error:", error);
  }
}
