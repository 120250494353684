export const convertNumberToOperatorAndNumber = (
  numericInput: string | null,
): { operator: string | null; number: number | null } => {
  if (!numericInput) return { operator: null, number: null };

  const match = numericInput.match(/^(<=|>=|<|>)?(\d+(\.\d+)?)$/);
  if (!match) return { operator: null, number: null };

  const [, operator = null, num] = match;
  const numberParsed = parseFloat(num);
  return { operator, number: numberParsed };
};
