import React from "react";
import Card from "../../components/Card";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";

interface Element {
  title: string;
  description: string;
  uri: string;
}

function Overview() {
  const { t } = useTranslation();
  const elements: Element[] = [
    {
      title: t("overview.dashboard.title"),
      description: t("overview.dashboard.description"),
      uri: "/dashboard",
    },
    // {
    //   title: t("overview.resultsHistory.title"),
    //   description: t("overview.resultsHistory.description"),
    //   uri: "/overview/myhistory",
    // },
    // {
    //   title: t("overview.loginSecurity.title"),
    //   description: t("overview.loginSecurity.description"),
    //   uri: "/overview/mysettings",
    // },
    {
      title: t("overview.customerService.title"),
      description: t("overview.customerService.description"),
      uri: "/overview/customerservice",
    },
  ];

  return (
    <div>
      <Box sx={{ flexGrow: 1, margin: "50px", width: "1000px" }}>
        <h1>{t("b2c.overview.yourAccount")}</h1>
        <Grid container spacing={4} justifyContent="center">
          {elements.map((element, index) => (
            <Grid item xs={12} sm={6} md={4} key={index} display="flex">
              <Card
                key={index}
                title={element.title}
                content={element.description}
                uri={element.uri}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    </div>
  );
}

export default Overview;
