import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { checkEmail } from "../utils/checkEmail";

const initialState = {
  isEmailValid: false,
  isEditingPatient: false,
};

export const settingsB2BSlice = createSlice({
  name: "settingsB2C",
  initialState,
  reducers: {
    checkValidEmail: (state, action: PayloadAction<string>) => {
      state.isEmailValid = checkEmail(action.payload);
    },
    updateIsEmailValid: (state, action: PayloadAction<boolean>) => {
      state.isEmailValid = action.payload;
    },
    updateIsEditingPatient: (state, action: PayloadAction<boolean>) => {
      state.isEditingPatient = action.payload;
    },
  },
});

export const { checkValidEmail, updateIsEmailValid, updateIsEditingPatient } =
  settingsB2BSlice.actions;
