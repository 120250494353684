import type { Patient } from "../../store/patientB2BSlice";

export const createFhirPatient = (patient: Patient): any => {
  const fhirPatient: any = {
    resourceType: "Patient",
    meta: {
      profile: [
        "http://www.saude.gov.br/fhir/r4/StructureDefinition/BRIndividuo-1.0",
      ],
    },
    identifier: [
      {
        use: "official",
        type: {
          coding: [
            {
              system:
                "http://www.saude.gov.br/fhir/r4/ValueSet/BRTipoDocumentoIndividuo-1.0",
              code: patient.identifierType,
            },
          ],
        },
        value: patient.identifierValue,
      },
    ],
    extension: [
      {
        url: "http://www.saude.gov.br/fhir/r4/StructureDefinition/BRPais-1.0",
        valueCodeableConcept: {
          coding: [
            {
              system: "urn:iso:std:iso:3166",
              code: patient.country,
              display: "Brazil",
            },
          ],
        },
      },
    ],
    name: [
      {
        use: "official",
        text: `${patient.nameGiven} ${patient.nameFamily}`,
        family: patient.nameFamily,
        given: patient.nameGiven.split(" "), // has to be array in google fhir, not in azure (!!!)
      },
    ],
    telecom: [
      {
        system: "phone",
        value: patient.phone,
        use: "mobile",
      },
      {
        system: "email",
        value: patient.email,
      },
    ],
    gender: patient.sex,
    birthDate: patient.birthDate,
    address: [
      {
        line: [
          patient.addressStreet,
          patient.addressStreetNumber,
          patient.addressNeighborhood,
        ],
        city: patient.city,
        state: patient.state,
        postalCode: patient.postalCode,
        country: patient.country,
      },
    ],
  };

  if (patient.fhirId) {
    fhirPatient.id = patient.fhirId;
  }

  return fhirPatient;
};
