import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PatientData } from "./rawDataB2C";

interface DataState {
  transformedData: PatientData["rawData"];
  visitDates: string[];
  loincNames: string[];
  observationNames: string[];
  selectedObservation: number;
  observationUnit: string;
  axisRange: number[];
}

const initialState: DataState = {
  transformedData: [], // dataMatrix
  visitDates: [], // xLabels
  loincNames: [], // dataIndex (needs to be deprecated)
  observationNames: [], // yLabels
  selectedObservation: 0, // selectedTest // LOINC ID
  observationUnit: "", // unit
  axisRange: [0, 100],
};

export const activeDataB2CSlice = createSlice({
  name: "activeDataB2C",
  initialState,
  reducers: {
    updateTransformedData: (
      state,
      action: PayloadAction<PatientData["rawData"]>,
    ) => {
      state.transformedData = action.payload;
    },
    updateSelectedObservation: (
      state,
      action: PayloadAction<{
        selectedObservation: number;
        observationUnit: string;
        axisRange: number[];
      }>,
    ) => {
      state.selectedObservation = action.payload.selectedObservation;
      state.observationUnit = action.payload.observationUnit;
      state.axisRange = action.payload.axisRange;
    },
    updateLoincNames: (state, action: PayloadAction<string[]>) => {
      state.loincNames = action.payload;
    },
    updateObservationNames: (state, action: PayloadAction<string[]>) => {
      state.observationNames = action.payload;
    },
    updateVisitDates: (state, action: PayloadAction<string[]>) => {
      state.visitDates = action.payload;
    },
  },
});

export const activeDataB2C = (state: any) => state.activeDataB2C;

export const {
  updateSelectedObservation,
  updateTransformedData,
  updateLoincNames,
  updateObservationNames,
  updateVisitDates,
} = activeDataB2CSlice.actions;
