import React from "react";

export default function MyHistory() {
  return (
    <div>
      <h1>Results</h1>
      <p>Welcome to My History</p>
    </div>

    // add button and redirect to overview page
  );
}
