import { Observation } from "../store/patientB2BSlice";
import { Encounter } from "../store/patientB2BSlice";
import { createFhirEncounter } from "../types/fhir/encounter";
import { createFhirObservation } from "../types/fhir/observation";

// method can be CRUDE, create type
type Method = "POST" | "GET" | "PUT" | "DELETE";

export const handlePatientRequest = async (
  patientData: any,
  method: Method = "GET",
  accessToken: string,
) => {
  const APP_BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

  // if method is PUT, append the patient id to the URL
  let url = `${APP_BACKEND_URL}/fhir/Patient`;

  if (method === "PUT") {
    url += `/${patientData.id}`;
  }

  try {
    const response = await fetch(url, {
      method: method,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(patientData),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error saving patient data:", error);
    throw error;
  }
};

export const handleObservationRequest = async (
  method: Method = "GET",
  accessToken: string,
  observation: Observation,
  loincId: string,
  patientFhirId: string,
  encounterFhirId: string,
) => {
  const APP_BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

  // if method is PUT, append the patient id to the URL
  let url = `${APP_BACKEND_URL}/fhir/Observation`;

  if (method === "PUT" || method === "DELETE" || method === "GET") {
    url += `/${observation.fhirId}`;
  }

  const dataReady = createFhirObservation(
    observation as Observation, // Specify the type of observation as Observation
    loincId,
    patientFhirId,
    encounterFhirId,
  );

  try {
    const response = await fetch(url, {
      method: method,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body:
        method !== "DELETE" && method !== "GET"
          ? JSON.stringify(dataReady)
          : undefined,
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error saving patient data:", error);
    throw error;
  }
};

// handle encounter request
export const handleEncounterRequest = async (
  encounter: Encounter,
  method: Method = "POST",
  accessToken: string,
) => {
  const APP_BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

  // if method is PUT, append the patient id to the URL
  let url = `${APP_BACKEND_URL}/fhir/Encounter`;

  if (method === "PUT") {
    url += `/${encounter.fhirId}`;
  }

  let dataReady = null;
  if (method === "POST" || method === "PUT") {
    dataReady = createFhirEncounter(encounter);
  }

  try {
    const response = await fetch(url, {
      method: method,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(dataReady),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error saving patient data:", error);
    throw error;
  }
};

// get patient data for an email
export const getPatientDataForEmail = async (
  url: string,
  email: string,
  accessToken: string,
) => {
  try {
    const full_url = url + `/fhir/Patient?email=${email}`;
    const response = await fetch(full_url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching patient data:", error);
    throw error;
  }
};

// create fetchSpecimenRequest
export const handleSpecimenRequest = async (
  specimenFhirId: string,
  method: Method = "GET",
  accessToken: string,
  // specimen?: any, //TODO define the type of specimen if we ever decide to POST/PUT via this function
) => {
  const APP_BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

  // if method is PUT, append the patient id to the URL
  let url = `${APP_BACKEND_URL}/fhir/Specimen`;

  if (method !== "POST") {
    url += `/${specimenFhirId}`;
  }

  // let dataReady = null;
  if (method === "POST" || method === "PUT") {
    // dataReady = createFhirSpecimen(specimen);

    // warn user with error
    Error("Method not implemented yet");
  }

  try {
    const response = await fetch(url, {
      method: method,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      // body: JSON.stringify(dataReady),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error saving patient data:", error);
    throw error;
  }
};
