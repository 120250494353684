import { Navigate } from "react-router-dom";
import React from "react";
import Overview from "./Overview";
import MySettings from "./overview/MySettings";
import ContactUs from "./overview/ContactUs";
import MyHistory from "./overview/MyHistory";
import Dashboard from "./Dashboard";

export const b2cRoutes = [
  {
    path: "",
    element: <Navigate to="/overview" />,
    index: true,
  },
  {
    path: "overview",

    element: <Overview />,
  },
  {
    path: "dashboard",
    element: <Dashboard />,
  },
  {
    path: "overview/mysettings",
    element: <MySettings />,
  },
  {
    path: "overview/customerservice",
    element: <ContactUs />,
  },
  {
    path: "overview/myhistory",
    element: <MyHistory />,
  },
];
