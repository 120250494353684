import { convertNumberToOperatorAndNumber } from "./convertNumberToOperatorAndNumber";

export type ValidateInputTestType = {
  type: string;
  minNumeric: string | null;
  maxNumeric: string | null;
  textNegative: string | null;
  textPositive: string | null;
};

export default function validateInput(
  value: string,
  testInput: ValidateInputTestType,
): boolean[] {
  let isValid = false;
  let isOutOfRange = false;

  // transform testInput to lower case and "numerico" to "numeric"
  testInput.type = testInput.type.toLowerCase();

  let numberValue = value;

  // if result is string, return true if value does not match textNegative
  if (testInput.type === "string") {
    // check that value is in textNegative or textPositive
    isValid =
      value === testInput.textPositive || value === testInput.textNegative;
    isOutOfRange = value !== testInput.textNegative;

    // early return if value is not equal to textNegative
    return [isValid, isOutOfRange];
  }

  if (testInput.type === "numerico") {
    testInput.type = "numeric";
  }

  if (testInput.type === "numeric" || testInput.type === "qualitative") {
    // transformation comma to dot only necessary for numerical results in  (FHIR does not accept ,)
    numberValue = numberValue.replace(/,/g, ".");

    const numberPattern = /^-?\d+(\.\d+)?$/;
    isValid = numberPattern.test(numberValue);

    const { operator: operatorVRMin, number: referenceValueMin } =
      convertNumberToOperatorAndNumber(testInput.minNumeric);
    const { operator: operatorVRMax, number: referenceValueMax } =
      convertNumberToOperatorAndNumber(testInput.maxNumeric);

    const finalOperatorMin = operatorVRMin === null ? "<" : operatorVRMin;
    const finalOperatorMax = operatorVRMax === null ? ">" : operatorVRMax;

    // Check range only if it's a valid number
    if (isValid) {
      if (referenceValueMin !== null) {
        if (finalOperatorMin === "<") {
          if (parseFloat(numberValue) < referenceValueMin) {
            isOutOfRange = true;
          }
        }

        if (finalOperatorMin === "<=") {
          if (parseFloat(numberValue) <= referenceValueMin) {
            isOutOfRange = true;
          }
        }
      }
      if (referenceValueMax !== null) {
        if (finalOperatorMax === ">") {
          if (parseFloat(numberValue) > referenceValueMax) {
            isOutOfRange = true;
          }
        }

        if (finalOperatorMax === ">=") {
          if (parseFloat(numberValue) >= referenceValueMax) {
            isOutOfRange = true;
          }
        }
      }
    }
  }

  // Add the rest of your validation logic here

  return [isValid, isOutOfRange];
}
