import React from "react";
import LinePlot from "../../components/plots/LinePlot";
import { Grid, Box } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useTranslation } from "react-i18next";
import {
  selectPatientDataB2C,
  selectPatientSettings,
} from "../../store/rawDataB2C";
import { useAppSelector, useAppDispatch } from "../../store/hooks";
import { activeDataB2C } from "../../store/activeDataB2C";
import { transformObjectTo2DArray } from "../../utils/transformObjectTo2DArray";
import updateSelectedObservationThunk from "../../store/thunks/setSelectedObservationThunk";
import Heatmap from "../../components/plots/Heatmap";

export default function ResultsSingle() {
  const { t } = useTranslation();

  const statisticsData = useAppSelector(
    (state) => selectPatientDataB2C(state).statisticsData,
  );
  const dispatch = useAppDispatch();

  const patientSettings = useAppSelector(selectPatientSettings);
  const patientActiveData = useAppSelector((state) => activeDataB2C(state));
  const patientData = useAppSelector((state) => selectPatientDataB2C(state));

  const triggerSelectedTestChange = (index: number, loincID: string) => () => {
    //TODO needs refactoring to keep only loincID
    dispatch(
      updateSelectedObservationThunk(
        index,
        patientData.metadataClean[loincID].unit,
        patientData.referenceData[loincID],
        patientActiveData.transformedData[loincID],
        patientSettings.unit,
      ),
    );
  };

  const minValue =
    patientData.referenceData[
      patientActiveData.loincNames[patientActiveData.selectedObservation]
    ]?.min ?? 0;
  const maxValue =
    patientData.referenceData[
      patientActiveData.loincNames[patientActiveData.selectedObservation]
    ]?.max ?? 100;

  return (
    <Grid
      container
      columns={12}
      spacing={2}
      style={{ height: "70vh" }}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <Grid item xs={8} style={{ height: "auto" }}>
        {statisticsData &&
          patientActiveData.transformedData[
            patientActiveData.selectedObservation
          ] &&
          patientActiveData.transformedData[
            patientActiveData.selectedObservation
          ].length > 0 && (
            <Grid container style={{ height: "100%", maxHeight: 200 }}>
              <Grid item xs={4}>
                <Card
                  sx={{
                    borderRadius: "13px",
                    boxShadow: "none",
                    bgcolor: "grey.200",
                    margin: "10px",
                  }}
                >
                  <CardContent>
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      style={{ height: "100%" }}
                    >
                      <Typography variant="body2">
                        {t("b2c.resultsSingle.firstValue")}{" "}
                        {patientActiveData.transformedData[
                          patientActiveData.selectedObservation
                        ]
                          .find(
                            (value: any) =>
                              value !== null && value !== undefined,
                          )
                          ?.toFixed(1)}
                      </Typography>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={4}>
                <Card
                  sx={{
                    borderRadius: "13px",
                    boxShadow: "none",
                    bgcolor: "grey.200",
                    margin: "10px",
                  }}
                >
                  <CardContent>
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      style={{ height: "100%" }}
                    >
                      <Typography variant="body2">
                        {t("b2c.resultsSingle.lastValue")}{" "}
                        {patientActiveData.transformedData[
                          patientActiveData.selectedObservation
                        ]
                          .slice()
                          .reverse()
                          .find(
                            (value: any) =>
                              value !== null && value !== undefined,
                          )
                          ?.toFixed(1)}
                      </Typography>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={4}>
                <Card
                  sx={{
                    borderRadius: "13px",
                    boxShadow: "none",
                    bgcolor: "grey.200",
                    margin: "10px",
                  }}
                >
                  <CardContent>
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      style={{ height: "100%" }}
                    >
                      <Typography variant="body2">
                        {t("b2c.resultsSingle.change")}{" "}
                        {(
                          ((patientActiveData.transformedData[
                            patientActiveData.selectedObservation
                          ]
                            .slice()
                            .reverse()
                            .find(
                              (value: any) =>
                                value !== null && value !== undefined,
                            ) -
                            patientActiveData.transformedData[
                              patientActiveData.selectedObservation
                            ].find(
                              (value: any) =>
                                value !== null && value !== undefined,
                            )) /
                            patientActiveData.transformedData[
                              patientActiveData.selectedObservation
                            ].find(
                              (value: any) =>
                                value !== null && value !== undefined,
                            )) *
                          100
                        ).toFixed(1)}
                        %
                      </Typography>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          )}
        {Object.keys(patientActiveData.transformedData).length &&
          (patientData.metadataClean[
            patientActiveData.loincNames[patientActiveData.selectedObservation]
          ].type === "string" ? (
            <Heatmap
              data={[
                transformObjectTo2DArray(patientActiveData.transformedData)[
                  patientActiveData.selectedObservation
                ],
              ]}
              formattedXLabels={patientActiveData.visitDates}
              observationNames={[
                patientActiveData.observationNames[
                  patientActiveData.selectedObservation
                ],
              ]}
              loincNames={[
                patientActiveData.loincNames[
                  patientActiveData.selectedObservation
                ],
              ]}
              metadataClean={patientData.metadataClean}
              referenceData={patientData.referenceData}
              normalizedData={patientData.normalizedData}
              selectedObservation={patientActiveData.selectedObservation}
            />
          ) : (
            <LinePlot
              chartData={
                transformObjectTo2DArray(patientActiveData.transformedData)[
                  patientActiveData.selectedObservation
                ]
              }
              testName={
                patientActiveData.observationNames[
                  patientActiveData.selectedObservation
                ]
              }
              xLabels={patientActiveData.visitDates}
              title={
                patientActiveData.observationNames[
                  patientActiveData.selectedObservation
                ]
              }
              yLabels={patientActiveData.visitDates}
              ymin={patientActiveData.axisRange[0]}
              ymax={patientActiveData.axisRange[1]}
              refmin={minValue}
              refmax={maxValue}
              unit={patientSettings.unit}
              observationUnit={patientActiveData.observationUnit}
            />
          ))}
      </Grid>
      <Grid item xs={3} style={{ overflowY: "auto" }}>
        {statisticsData &&
          transformObjectTo2DArray(patientActiveData.transformedData).map(
            (test: string, index: number) => {
              const testName = patientActiveData.observationNames[index];
              const testStats = statisticsData["Reference values"][testName];

              return (
                <Card
                  sx={{
                    my: 2,
                    backgroundColor:
                      index === patientActiveData.selectedObservation
                        ? "lightblue"
                        : "initial",
                    cursor: "pointer",
                  }}
                  key={index}
                  onClick={() => {
                    const loincID = patientActiveData.loincNames[index];
                    triggerSelectedTestChange(index, loincID)();
                  }}
                >
                  <CardContent style={{ padding: "8px" }}>
                    <Typography variant="h5" component="div">
                      {patientActiveData.observationNames[index]}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {patientSettings.filter === "Reference values" &&
                      testStats &&
                      ((testStats.min >= 0 && testStats.min <= 100) ||
                        (testStats.max >= 0 && testStats.max <= 100)) ? (
                        <Typography
                          variant="body2"
                          color="green"
                          style={{ fontSize: "1.5em" }}
                        >
                          Within reference range
                        </Typography>
                      ) : (
                        <>
                          {patientSettings.filter === "Reference values" &&
                            testStats &&
                            testStats.min !== undefined && (
                              <Box display="flex" alignItems="center">
                                <ArrowDropDownIcon
                                  color="error"
                                  style={{ fontSize: "2.5em" }}
                                />
                                <Typography style={{ fontSize: "1.5em" }}>
                                  {Number(testStats.min).toFixed(1)}%
                                </Typography>
                              </Box>
                            )}
                          {testStats &&
                            testStats.min !== undefined &&
                            testStats.max !== undefined &&
                            ", "}
                          {patientSettings.filter === "Reference values" &&
                            testStats &&
                            testStats.max !== undefined && (
                              <Box display="flex" alignItems="center">
                                <ArrowDropUpIcon
                                  color="error"
                                  style={{ fontSize: "2.5em", margin: "0" }}
                                />
                                <Typography style={{ fontSize: "1.5em" }}>
                                  {Number(testStats.max).toFixed(1)}%
                                </Typography>
                              </Box>
                            )}
                        </>
                      )}
                    </Typography>
                  </CardContent>
                </Card>
              );
            },
          )}
      </Grid>
    </Grid>
  );
}
