import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchData, fetchDataStatistics } from "../services/api";

export interface PatientData {
  rawData: number[][]; // patientResults
  metadata: string[]; // new metadata from /api/data/metadata
  metadataClean: { [key: string]: string }; // clean metadata from /api/data/metadata
  normalizedData: number[][]; //patientResultsRef is from /api/data/normalize
  statisticsData: number[][][]; //statisticsData is from fetchStatisticsData("/api/data/statistics", visit);
  referenceData: any; // refrenceData is from fetchReferenceValuesAsync("/api/refvals");
}

interface PatientSettings {
  visit: string;
  filter: string;
  unit: string;
}

export interface rawDataB2C {
  data: PatientData;
  visit: string; // visit
  visits: string[];
  filters: string[]; // can be Alphabetical order
  settings: PatientSettings;
  status: "idle" | "loading" | "failed";
  statisticsStatus: "idle" | "loading" | "failed";
  errorMessage?: string | null;
}

export const getPatientData = createAsyncThunk(
  "rawDataB2C/fetchData",
  async (accessToken: string) => {
    const responseRawData = await fetchData("/api/data", accessToken);

    const responseMetadata = await fetchData("/api/data/metadata", accessToken);

    const responseReferenceData = await fetchData("/api/refvals", accessToken);

    // The value we return becomes the `fulfilled` action payload
    return { responseRawData, responseReferenceData, responseMetadata };
  },
);

export const getStatisticsData = createAsyncThunk(
  "rawDataB2C/fetchStatisticsData",
  async ({ accessToken, visit }: { accessToken: string; visit: string }) => {
    const responseStatisticsData = await fetchDataStatistics(
      "/api/data/statistics",
      visit,
      accessToken,
    );

    const responseNormalizedData = await fetchData(
      "/api/data/normalize",
      accessToken,
    );

    // The value we return becomes the `fulfilled` action payload
    return { responseStatisticsData, responseNormalizedData };
  },
);

export const rawDataB2C = createSlice({
  name: "rawDataB2C",
  initialState: {
    data: {
      rawData: {},
      metadata: {},
      metadataClean: {},
      normalizedData: {},
      statisticsData: {},
      referenceData: {},
    },
    settings: {
      visit: "",
      filter: "Alphabetical order", //needs a better name
      unit: "Absolute", //needs a better name
    },
    visits: [] as string[],
    filters: [] as string[],
    status: "idle",
    statisticsStatus: "idle",
    errorMessage: null as string | null,
  },
  reducers: {
    setVisit: (state, action) => {
      state.settings.visit = action.payload;
    },
    setFilter: (state, action) => {
      state.settings.filter = action.payload;
    },
    setUnit: (state, action) => {
      state.settings.unit = action.payload;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(getPatientData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getPatientData.rejected, (state, action) => {
        state.errorMessage = action.error.message
          ? action.error.message
          : "Unknown error";
        state.status = "failed";
      })
      .addCase(getPatientData.fulfilled, (state, action) => {
        state.status = "idle";
        const { responseRawData, responseReferenceData, responseMetadata } =
          action.payload;

        // Set the rawData and referenceData
        state.data.rawData = responseRawData;
        state.data.referenceData = responseReferenceData;
        state.data.metadata = responseMetadata;
        state.errorMessage = null;

        // Set metadata clean (filter only one key per test)

        const data_keys = Object.keys(responseRawData);

        state.data.metadataClean = data_keys.reduce(
          (
            acc: {
              [key: string]: { name: string; unit: string; type: string };
            },
            key: string,
          ) => {
            const metadataEntry = responseMetadata.find(
              (meta: any) => meta.loinc === key,
            );
            if (metadataEntry) {
              acc[key] = {
                name: metadataEntry.name,
                unit: metadataEntry.unit,
                type: metadataEntry.type,
              };
            }
            return acc;
          },
          {},
        );

        // Set the visits and visit
        const firstElement: any = Object.values(responseRawData)[0];
        const visitDates = Object.keys(firstElement);
        state.visits = visitDates;
        state.settings.visit = visitDates[visitDates.length - 1];
      })
      .addCase(getStatisticsData.pending, (state) => {
        state.statisticsStatus = "loading";
      })
      .addCase(getStatisticsData.rejected, (state) => {
        state.statisticsStatus = "failed";
      })
      .addCase(getStatisticsData.fulfilled, (state, action) => {
        state.statisticsStatus = "idle";
        const { responseStatisticsData, responseNormalizedData } =
          action.payload;
        state.data.statisticsData = responseStatisticsData;
        state.data.normalizedData = responseNormalizedData;

        // Set the filters
        state.filters = Object.keys(responseStatisticsData).concat(
          "Alphabetical order",
        );
      });
  },
});

export const { setVisit, setFilter, setUnit } = rawDataB2C.actions;

export const selectPatientDataB2C = (state: any) => state.rawDataB2C.data;
export const selectPatientVisits = (state: any) => state.rawDataB2C.visits;
export const selectPatientFilters = (state: any) => state.rawDataB2C.filters;
export const selectPatientSettings = (state: any) => state.rawDataB2C.settings;
export const selectPatientvisit = (state: any) =>
  state.rawDataB2C.settings.visit;
export const selectErrorMessage = (state: any) => state.rawDataB2C.errorMessage;

export const selectStatus = (state: any) => state.rawDataB2C.status;
export const selectStatisticsStatus = (state: any) =>
  state.rawDataB2C.statisticsStatus;

export default rawDataB2C.reducer;
