import React from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  choosePatient,
  selectPatients,
  removePatient,
  selectChoosePatient,
} from "../../store/patientB2BSlice";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from "react-router-dom";
import {
  updateIsEditingPatient,
  updateIsEmailValid,
} from "../../store/settingsB2B";
import { useTranslation } from "react-i18next";

function PatientList() {
  const { t } = useTranslation();
  const patients = useSelector(selectPatients);
  const selectedPatient = useSelector(selectChoosePatient);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isEditingPatient = useSelector(
    (state: any) => state.settingsB2B.isEditingPatient,
  );

  const handleSelectPatient = (id: string | null) => {
    // if editing patient, do not perform any action

    if (isEditingPatient) {
      return;
    }

    // if selectedPatiet matches id, set choosePatient to null
    if (selectedPatient === id) {
      dispatch(choosePatient(null));
      return;
    }

    dispatch(choosePatient(id));
  };

  const handleRemovePatient = (id: string) => {
    if (selectedPatient === id) {
      handleSelectPatient(null);
    }
    dispatch(removePatient(id));
  };

  return Object.keys(patients).length === 0 ? (
    <Grid item xs={3}>
      <Card
        key={"no-patient"}
        style={{
          marginBottom: "10px",
          backgroundColor: "white",
        }}
      >
        <CardContent>
          <Typography variant="h6" style={{ marginTop: "10px" }}>
            {t("b2b.patientList.noPatients")}
          </Typography>
        </CardContent>
      </Card>
    </Grid>
  ) : (
    <Grid item xs={3}>
      {Object.entries(patients).map(([id, patient]: [string, any]) => (
        <Card
          key={id}
          style={{
            marginBottom: "10px",
            backgroundColor: id === selectedPatient ? "#f1f1f1" : "white",
          }}
          onClick={() => handleSelectPatient(id)}
        >
          <CardContent>
            <Typography variant="h5" component="h2">
              {patient.name}
            </Typography>
            <Typography color="textSecondary">{patient.email}</Typography>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Button
                startIcon={<DeleteIcon />}
                onClick={(e) => {
                  e.stopPropagation(); // Prevent card onClick from firing
                  handleRemovePatient(id);
                }}
                color="error"
                disabled={isEditingPatient}
              >
                {t("b2b.patientList.delete")}
              </Button>
              {patient.isNewPatient
                ? t("b2b.patientList.newPatient")
                : t("b2b.patientList.existingPatient")}
              <Button
                startIcon={<EditIcon />} // Assuming you have an EditIcon imported
                disabled={isEditingPatient}
                onClick={(e) => {
                  e.stopPropagation(); // Prevent card onClick from firing
                  dispatch(updateIsEmailValid(true));
                  dispatch(updateIsEditingPatient(true));
                  dispatch(choosePatient(id));

                  // navigate to /createpatient
                  navigate("/createpatient");
                }}
                color="primary"
              >
                {t("b2b.patientList.edit")}
              </Button>
            </div>
          </CardContent>
        </Card>
      ))}
    </Grid>
  );
}

export default PatientList;
